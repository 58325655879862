<template>
  <div>
    <b-button
      size="sm"
      variant="secondary"
      class="my-2 btn-action "
      style="border-style: none;"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
      <feather-icon icon="ArrowLeftIcon" size="25" />
    </b-button>
    <b-card :title="$i18n.messages.Pedido + ' ' + order.Pedido">
      <b-row>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Fecha }}:</strong>
            {{ order.Fecha }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Ref }}:</strong>
            {{ order.Referencia }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Amount }}:</strong>
            {{
              order.Importe + " " + order.Moneda + " " + $i18n.messages.Taxes
            }}
          </p>
        </b-col>

        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.EstadoPedido }}:</strong>
            <b-badge pill :variant="colorPedido(order.Estado)" class="ml-1">
              {{ statusPedido(order.Estado) }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Tasa }}:</strong>
            {{ order.Tasa_energetica }}
          </p>
        </b-col>

        <b-col cols="12" lg="12" class="mt-3" v-if="hasLineas">
          <b-table
            striped
            responsive
            :stacked="stacked"
            :items="order.lineas"
            :fields="fields"
          >
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BBadge, BButton } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { config } from "../shared/app.config";
import moment from "moment";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BBadge,
  },
  data() {
    return {
      stacked: false,
      modal: true,
      fechaFormatted: "",
      fields: [
        {
          key: "Codigo",
          label: this.$i18n.messages.Code,
        },
        {
          key: "Descripcion",
          label: this.$i18n.messages.Description,
        },
        {
          key: "Precio neto",
          label: this.$i18n.messages.PriceUnit,
          formatter(value, key, item) {
            return Number(value).toLocaleString("de-DE", {
              maximumFractionDigits: 4,
            });
          },
        },
        {
          key: "Unidad",
          label: this.$i18n.messages.Unidad,
        },
        {
          key: "Cantidad pendiente",
          label: this.$i18n.messages.AmountPending,
        },
        {
          key: "Cantidad preparada",
          label: this.$i18n.messages.AmountPrepared,
        },
        {
          key: "Cantidad servida",
          label: this.$i18n.messages.AmountServed,
        },

        {
          key: "Peso",
          label: this.$i18n.messages.weight,
        },
        {
          key: "Importe",
          label: this.$i18n.messages.Amount + " (EUR)",
        },
        {
          key: "Tasa_energetica",
          label: this.$i18n.messages.Tasa,
        },
      ],
    };
  },
  mounted() {
    this.stacked = window.innerWidth <= 1500;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 1500;
    });
  },
  created() {
    this.setLoading(true);
    this.getOrder(this.$route.params.id).then(() => {
      if (this.order.fechaCarga != "undefined") {
        var momentObj = moment(this.order.fechaCarga, "YYYYMMDD");
        var momentString = momentObj.format("DD-MM-YYYY");
        this.fechaFormatted = momentString;
      }

      this.setLoading(false);
    });
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    hasLineas() {
      if (this.order.lineas) {
        return this.order.lineas.length;
      } else {
        return false;
      }
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
    }),

    colorPedido(val) {
      return config.colorPedidos[val];
    },
    statusPedido(val) {
      return config.estadosPedidos[val];
    },
  },
};
</script>
